try{Typekit.load();}catch(e){}

$( document ).ready(function() {
    $(".forgot-pass").click(function (e){
        e.preventDefault();
        $(".login-f").hide();
        $(".forgot-p").show();
        $(".forgot-pass").hide();
        $(".remember-pass").show();
    });

    $(".remember-pass").click(function (e){
        e.preventDefault();
        $(".login-f").show();
        $(".forgot-p").hide();
        $(".forgot-pass").show();
        $(".remember-pass").hide();
    });

    $(".submit-form").click(function (e){
        $(".login-f").submit();
        $(".login-f").hide();
        $(".options-container").hide();
        $(".loading").show();
    });
});