/**
 * SamCart Core
 *
 * Copyright (C) 2014-2016 SamCart, LLC. All Rights Reserved.
 * This software may not be redistributed in any form.
 *
 */

(function(){

    "use strict";

    var Signup = {

        type : null,
        email_taken : false,
        domain_taken : false,
        email_val : '',
        form_data : {},
        bump_selected : false,

        init : function() {
            var _this = this;

            this.setElements();

            this.cc_num.payment('formatCardNumber');
            this.cc_exp.payment('formatCardExpiry');
            this.cc_cvc.payment('formatCardCVC');

            this.form.submit(function(e) {
                e.preventDefault();
                _this.form.removeClass('shake');
                if (_this.validate()) {
                    _this.submit.addClass('loading');
                    _this.submit.val("");
                    _this.submit.prop('disabled', true);
                    _this.email_val = _this.email.val();
                    _this.form_data = {
                        'email' : _this.email.val(),
                        'slug' : _this.slug.val(),
                        'plan' : _this.plan.val(),
                        'products' : _this.products.val()
                    };
                    _this.signup({
                        'email' : _this.email.val(),
                        'cc_num' : _this.cc_num.val(),
                        'cc_exp' : _this.cc_exp.val(),
                        'cc_cvc' : _this.cc_cvc.val()
                    });
                } else {
                    _this.shake();
                }
            });

            this.marketplace_form.submit(function(e) {
                e.preventDefault();
                _this.form.removeClass('shake');
                if (_this.validateMarketplace()) {
                    _this.marketplace_submit.addClass('loading');
                    _this.marketplace_submit.val("");
                    var subdomain = _this.parseSubdomain(_this.subdomain.val());
                    _this.signupStep2({
                        'marketplace_name' : _this.marketplace.val(),
                        'subdomain' : subdomain,
                        'password' : _this.password.val(),
                        'token' : _this.token.val()
                    });
                } else {
                    _this.marketplace_shake();
                }
            });

            this.cc_num.keyup(function(){
                _this.type = $.payment.cardType($(this).val());
                if (_this.type !== null) {
                    _this.greyCC();
                    _this.highlight(_this.type);
                } else {
                    _this.clearCC();
                }
            });

            this.cc_exp.keydown(function(e){
                var code = (e.keyCode ? e.keyCode : e.which);
                if ($(this).val().length === 7 && (code !== 8 && code !== 9 && code !== 46 && code !== 37 && code !== 38 && code !== 39 && code !== 40)) {
                    e.preventDefault();
                }
            });

            this.email.on('blur', function() {
                var email = $(this).val();
                var ele = this;
                if (email !== '') {
                    $(ele).removeClass('empty');
                }
                if (!_this.validateEmail(email)) {
                    $(ele).removeClass('valid');
                    $(this).addClass('invalid');
                    return false;
                }
                $(ele).addClass('loading');
                $(ele).removeClass('valid');
                $(ele).removeClass('invalid');
                if ((email !== _this.email_val) && (email !== '')) {
                    _this.postEmail(email, function(success) {
                        $(ele).removeClass('loading');
                        if (success) {
                            _this.email_taken = false;
                            $(ele).removeClass('invalid');
                            $(ele).addClass('valid');
                        } else {
                            _this.email_taken = true;
                            $(ele).removeClass('valid');
                            $(ele).addClass('invalid');
                        }
                    });
                }
            });

            this.cc_num.on('blur', function() {
                _this.validateCC();
            });

            this.cc_exp.on('blur', function() {
                _this.validateExp();
            });

            this.cc_cvc.on('blur', function() {
                _this.validateCVC();
            });

            this.marketplace.on('keydown', function(e) {
                var value = $(this).val();
                if (value === '') {
                    _this.subdomain.val('');
                    return true;
                }
                value = value.toLowerCase();
                value = value.replace(/[^a-zA-Z]/g, "");
                _this.subdomain.val(value + '.samcart.com');
            });

            this.subdomain.on('keyup', function(e) {
                var code = (e.keyCode ? e.keyCode : e.which);
                if (code == 9) {
                    var value = _this.subdomain.val();
                    if (value === '') {
                        return true;
                    }
                    value = value.replace('.samcart.com', '');
                    value = value.replace('samcart.com', '');
                    var length = value.length;
                    value = value + '.samcart.com';
                    _this.subdomain.val(value);
                    _this.subdomain.selectRange(0, length);
                }
            });

            this.subdomain.on('keydown', function(e){
                var code = (e.keyCode ? e.keyCode : e.which);
                if ((code < 65 || code > 90) && code !== 46 && code !== 8 && code !== 9) {
                    e.preventDefault();
                }
                if (code === 8) {
                    return true;
                }
                var value = $(this).val();
                value = value.replace('.samcart.com', '');
                value = value.replace('samcart.com', '');
                value = value.toLowerCase();
                var length = value.length;
                if (length > 16 && (code !== 8 && code !== 9)) {
                    e.preventDefault();
                }
                value = value + '.samcart.com';
                $(this).val(value);
                $(this).selectRange(length);
            });

            this.subdomain.on('focus', function(e){
                var value = $(this).val();
                if (value === '') {
                    return true;
                }
                value = value.replace('.samcart.com', '');
                value = value.replace('samcart.com', '');
                var length = value.length;
                value = value + '.samcart.com';
                $(this).val(value);
                $(this).selectRange(0, length);
            });

            $(window).resize(function(){
                _this.setElements();
            });

            this.bump_toggle.on('click', function() { 
                _this.bump_selected = !_this.bump_selected;
                $(this).removeClass('ghost-blue', 'blue');
                if (_this.bump_selected) {
                    $('.graphic-pitch .graphic').removeClass('grey-scale');
                    _this.addProduct('checkout-hacks', _this.products);
                    $(this).addClass('blue');
                    $(this).html('<svg class="check-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#confirm-icon"></use></svg> Checkout Hacks will be added to your account!');
                } else {
                    $('.graphic-pitch .graphic').addClass('grey-scale');
                    _this.removeProduct('checkout-hacks', _this.products);
                    $(this).addClass('ghost-blue');
                    $(this).html('<svg class="check-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#unselected-confirm-icon"></use></svg> Yes, add \'Checkout Hacks\' to my free account');
                }
            });

            this.one_time_sale.on('click', function() {
                _this.products.val('samcart-go-package');
                $(this).addClass('selected');
                _this.payment_plan.removeClass('selected');
            });

            this.payment_plan.on('click', function() {
                _this.products.val('samcart-go-package-payment-plan');
                $(this).addClass('selected');
                _this.one_time_sale.removeClass('selected');
            });
        },

        setElements : function() {
            if(window.innerWidth < 768 && $("#mobile_signup_form").length > 0) {
                this.form = $("#mobile_signup_form");
                this.one_time_sale = $("#mobile_signup_form .input.one-time-sale");
                this.payment_plan = $("#mobile_signup_form .input.payment-plan");
                this.email = $("#mobile_signup_form input.email");
                this.cc_num = $('#mobile_signup_form input.cc-num');
                this.cc_exp = $('#mobile_signup_form input.cc-exp');
                this.cc_cvc = $('#mobile_signup_form input.cc-cvc');
                this.terms = $('#mobile_signup_form .input.terms input');
                this.slug = $('#mobile_signup_form .input input.slug');
                this.plan = $('#mobile_signup_form .input input.plan');
                this.submit = $('#mobile_signup_form .submit input');
                this.error = $("#mobile_signup_form .signup-error h4");
                this.inputs = $("#mobile_signup_form input.email, #mobile_signup_form input.cc-num, #mobile_signup_form input.cc-exp, #mobile_signup_form input.cc-cvc");
                this.products = $('#mobile_signup_form input.products');
            } else {
                this.form = $("#signup_form");
                this.one_time_sale = $("#signup_form .input.one-time-sale");
                this.payment_plan = $("#signup_form .input.payment-plan");
                this.email = $("#signup_form input.email");
                this.cc_num = $('#signup_form input.cc-num');
                this.cc_exp = $('#signup_form input.cc-exp');
                this.cc_cvc = $('#signup_form input.cc-cvc');
                this.terms = $('#signup_form .input.terms input');
                this.slug = $('#signup_form .input input.slug');
                this.plan = $('#signup_form .input input.plan');
                this.submit = $('#signup_form .submit input');
                this.error = $("#signup_form .signup-error h4");
                this.inputs = $("#signup_form input.email, #signup_form input.cc-num, #signup_form input.cc-exp, #signup_form input.cc-cvc");
                this.products = $("#signup_form input.products");
                this.plan_toggle = $('.plan-toggle');
            }
            this.bump_toggle = $(".bump .input .button");
            this.conversionPixel = $("#conversionPixel");
            this.marketplace_form = $("#marketplace_form");
            this.marketplace_error = $("#marketplace_form .signup-error h4");
            this.marketplace = $("#marketplace_form input.marketplace");
            this.subdomain = $("#marketplace_form input.subdomain");
            this.password = $("#marketplace_form input.password");
            this.token = $("#marketplace_form > input");
            this.marketplace_submit = $("#marketplace_form .submit input");
            this.marketplace_inputs = $("#marketplace_form input.marketplace, #marketplace_form input.subdomain, #marketplace_form input.password");
        },

        addProduct : function(product, productElement) {
            var value = productElement.val() + ',' + product;
            productElement.val(value);
        },

        removeProduct : function(product, productElement) {
            var value = productElement.val().replace(',' + product, '');
            productElement.val(value);
        },

        postEmail : function(email, fn) {
            $.post('/signup/email', {'email' : this.email.val()}, function(success) {
                fn(success);
            });
        },

        postSubdomain : function(subdomain, fn) {
            $.post('/signup/subdomain', {'subdomain' : this.parseSubdomain(this.subdomain.val())}, function(success) {
                fn(success);
            });
        },

        clearCC : function() {
            $('.cards .mastercard use').attr('xlink:href', '#mastercard');
            $('.cards .visa use').attr('xlink:href', '#visa');
            $('.cards .discover use').attr('xlink:href', '#discover');
            $('.cards .amex use').attr('xlink:href', '#amex');
            $('.cards .dinersclub use').attr('xlink:href', '#dinersclub');
            $('.cards .jcb use').attr('xlink:href', '#jcb');
        },

        greyCC : function() {
            $('.cards .mastercard use').attr('xlink:href', '#mastercard-gs');
            $('.cards .visa use').attr('xlink:href', '#visa-gs');
            $('.cards .discover use').attr('xlink:href', '#discover-gs');
            $('.cards .amex use').attr('xlink:href', '#amex-gs');
            $('.cards .dinersclub use').attr('xlink:href', '#dinersclub-gs');
            $('.cards .jcb use').attr('xlink:href', '#jcb-gs');
        },

        highlight : function(card) {
            $('.cards .' + card + ' use').attr('xlink:href', '#' + card);
        },

        validate : function() {

            var _this = this;
            this.error.html('');
            this.checkEmpty();
            if (this.email_taken) {
                this.error.html('That email is already taken.');
                return false;
            }

            var empty = false;

            this.inputs.each(function() {
                if ($(this).hasClass('empty')) {
                    empty = true;
                }
            });

            if (empty) {
                this.error.html('All fields are required.');
                return false;
            }

            if (!this.validateEmail(this.email.val())) {
                this.error.html('The email is invalid.');
                return false;
            }

            if (!this.validateCC()) {
                this.error.html('The card number is invalid.');
                return false;
            }

            if (!this.validateExp()) {
                this.error.html('The expiration date is invalid.');
                return false;
            }

            if (!this.validateCVC()) {
                this.error.html('The CVC you entered is invalid.');
                return false;
            }

            if (!this.checkTerms()) {
                this.error.html('You must agree to the terms of service');
                return false;
            }

            return true;
        },

        validateEmail : function(email) {
            return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email);
        },

        validateCC : function() {
            var cc_num = this.cc_num.val();
            if (cc_num === '') {
                this.cc_num.removeClass('valid');
                this.cc_num.addClass('invalid');
                return false;
            }
            this.cc_num.removeClass('empty');
            var valid = $.payment.validateCardNumber(cc_num);
            if (!valid) {
                this.cc_num.addClass('invalid');
                this.cc_num.removeClass('valid');
                return false;
            }
            this.cc_num.removeClass('invalid');
            this.cc_num.addClass('valid');
            return true;
        },

        validateExp : function() {
            var exp = this.cc_exp.val();
            if (exp === '') {
                this.cc_exp.removeClass('valid');
                this.cc_exp.addClass('invalid');
                return false;
            }
            this.cc_exp.removeClass('empty');
            exp = this.parseExp(exp);
            var valid = $.payment.validateCardExpiry(exp.month, exp.year);
            if (!valid) {
                this.cc_exp.addClass('invalid');
                this.cc_exp.removeClass('valid');
                return false;
            }
            this.cc_exp.removeClass('invalid');
            this.cc_exp.addClass('valid');
            return true;
        },

        validateCVC : function() {
            var cvc = this.cc_cvc.val();
            if (cvc === '') {
                this.cc_cvc.removeClass('valid');
                this.cc_cvc.addClass('invalid');
                return false;
            }
            this.cc_cvc.removeClass('empty');
            var card_type = $.payment.cardType(this.cc_num.val());
            var valid = false;
            if (card_type !== null) {
                valid = $.payment.validateCardCVC(cvc, card_type);
            } else {
                valid = $.payment.validateCardCVC(cvc);
            }
            if (!valid) {
                this.cc_cvc.addClass('invalid');
                this.cc_cvc.removeClass('valid');
                return false;
            }
            this.cc_cvc.removeClass('invalid');
            this.cc_cvc.addClass('valid');
            return true;
        },

        checkTerms : function() {
            if (!this.terms.is(':checked')) {
                return false;
            }
            return true;
        },

        parseExp : function(exp) {
            var date = exp.split(' / ');
            return {
                month : date[0],
                year : date[1]
            };
        },

        checkEmpty : function() {
            this.inputs.removeClass('empty');
            this.inputs.each(function() {
                if ($(this).val() === '') {
                    $(this).addClass('empty');
                    return;
                }
            });
        },

        clear : function() {
            this.inputs.removeClass('invalid');
            this.inputs.removeClass('empty');
            this.inputs.removeClass('valid');
            this.error.html('');
        },

        shake : function() {
            var _this = this;
            if (!_this.form.hasClass('shake')) {
                _this.form.addClass('shake');
            }
            setTimeout(function() {
                if (_this.form.hasClass('shake')) {
                    _this.form.removeClass('shake');
                }
            }, 821);
        },

        marketplace_shake : function() {
            var _this = this;
            if (!_this.marketplace_form.hasClass('shake')) {
                _this.marketplace_form.addClass('shake');
            }
            setTimeout(function() {
                if (_this.marketplace_form.hasClass('shake')) {
                    _this.marketplace_form.removeClass('shake');
                }
            }, 821);
        },

        signup : function(data, callback) {
            var exp_date = this.parseExp(data.cc_exp);
            Stripe.card.createToken({
                number: data.cc_num,
                cvc: data.cc_cvc,
                exp_month: exp_date.month,
                exp_year: exp_date.year
            }, this.stripeCallback);
        },

        stripeCallback : function(status, response) {
            if (status !== 200) {
                Signup.submit.removeClass('loading');
                Signup.submit.val("Activate My Account Now!");
                return;
            }
            if (response.error) {
                Signup.submit.removeClass('loading');
                Signup.submit.val("Activate My Account Now!");
                Signup.handleStripeError(response);
                return;
            }
            Signup.postSignup(Signup.form_data, response);
        },

        handleStripeError : function(response) {
            this.error.html(response.error.message);
            this.submit.removeClass('loading');
            this.submit.val("Activate My Account Now!");
            this.shake();
        },

        handleSignupError : function(response) {
            this.error.html(response.message);
            this.submit.removeClass('loading');
            this.submit.prop('disabled', false);
            this.submit.val("Activate My Account Now!");
            this.shake();
        },

        postSignup : function(form_data, response) {
            var _this = this;
            form_data.token = response.id;
            $.post('/signup', form_data, function(post_response) {
                if (post_response.success) {
                    if (post_response.data.url_redirect !== undefined && post_response.data.url_redirect !== '') {
                        _this.checkoutAnalytics(_this.products.val());
                        window.location = post_response.data.url_redirect;
                    } else {
                        //_this.showMarketplace(post_response.data.token);
                    }
                } else {
                    _this.handleSignupError(post_response.data);
                }
            });
        },

        //Marketplace functions

        showMarketplace : function(token) {
            $('section.signup > h1').html('Almost There!');
            $('section.signup > h3').html('Please enter in a little more information so we can generate your online marketplace.');
            $('section.signup > h4').html('');
            this.form.fadeOut();
            this.token.val(token);
            this.marketplace_form.delay(800).fadeIn();
            $('nav.auth').fadeOut();
            $('.carousel.signup').fadeOut();
            $('.signup.go .container').fadeOut();
            $('.white.pitch').fadeOut();
            $('.light-border-grey.support').fadeOut();
            $('.marketplace-fade-out').fadeOut(800, function(){
                $('section.signup .signup-container').css({
                    'width' : '18rem'
                });
            });
        },

        handleMarketplaceError : function(response) {
            this.marketplace_error.html(response.message);
            this.marketplace_submit.removeClass('loading');
            this.marketplace_submit.val("Activate My Account Now!");
            this.marketplace_shake();
        },

        signupStep2 : function(data) {
            var _this = this;
            $.post('/signup_part_two', data, function(response) {
                if (response.success) {
                    window.location = response.data.redirect_url;
                } else {
                    _this.marketplace_submit.removeClass('loading');
                    _this.marketplace_submit.val("Activate My Account Now!");
                    _this.handleMarketplaceError(response.data);
                }
            });
        },

        parseSubdomain : function(value) {
            value = value.replace('.samcart.com', '');
            value = value.replace('samcart.com', '');
            value = value.toLowerCase();
            return value;
        },

        validateMarketplace : function() {
            var _this = this;
            this.marketplace_error.html('');
            this.checkMarketplaceEmpty();
            if (this.domain_taken) {
                this.marketplace_error.html('That subdomain is already taken.');
                return false;
            }

            var empty = false;

            this.marketplace_inputs.each(function() {
                if ($(this).hasClass('empty')) {
                    empty = true;
                }
            });

            if (empty) {
                this.marketplace_error.html('All fields are required.');
                return false;
            }

            return true;
        },

        checkMarketplaceEmpty : function() {
            this.marketplace_inputs.removeClass('empty');
            this.marketplace_inputs.each(function() {
                if ($(this).val() === '') {
                    $(this).addClass('empty');
                    return;
                }
            });
        },

        checkoutAnalytics : function(product) {
            if (product === 'samcart-go-package') {
                this.conversionPixel.attr('src', '//app.webinarjam.net/tracker?action=sale&webicode=1c16e1f447&productID=116094');
                fbq('track', 'Purchase', {
                    value: '997.00',
                    currency:'USD',
                    content_name: product,
                    purchase_type: 'new signup',
                    referrer: document.referrer
                });
            } else if (product === 'samcart-go-package-payment-plan') {
                this.conversionPixel.attr('src', '//app.webinarjam.net/tracker?action=sale&webicode=3a2028d746&productID=74159');
                fbq('track', 'Purchase', {
                    value: '99.00',
                    currency:'USD',
                    content_name: product,
                    purchase_type: 'new signup',
                    referrer: document.referrer
                });
            } else {
                fbq('track', 'Purchase', {
                    value: '0.00',
                    currency:'USD',
                    content_name: product,
                    purchase_type: 'new signup',
                    referrer: document.referrer
                });
            }
        }

    };

    $(document).ready(function(){

        Signup.init();

    });

})();

$.fn.selectRange = function(start, end) {
    if(end === undefined) {
        end = start;
    }
    return this.each(function() {
        if('selectionStart' in this) {
            this.selectionStart = start;
            this.selectionEnd = end;
        } else if(this.setSelectionRange) {
            this.setSelectionRange(start, end);
        } else if(this.createTextRange) {
            var range = this.createTextRange();
            range.collapse(true);
            range.moveEnd('character', end);
            range.moveStart('character', start);
            range.select();
        }
    });
};
