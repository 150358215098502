/**
 * SamCart Core
 *
 * Copyright (C) 2014-2016 SamCart, LLC. All Rights Reserved.
 * This software may not be redistributed in any form.
 *
 */

(function(){

    "use strict";

    var Landing = {

        carousel : {
            init : function(carousel) {
                var _this = this;
                var Carousel = {
                    clicked : false,
                    element : carousel,
                    slides : function() {
                        return carousel.children('.slide-container').children('.slide');
                    },
                    position : 0,
                    count : function() {
                        return this.slides().length;
                    },
                    right_arrow : carousel.children('.slide-container').children('.right-arrow'),
                    left_arrow : carousel.children('.slide-container').children('.left-arrow'),
                    switchRight : function() {
                        var index1 = this.position;
                        this.position++;
                        if (this.position === this.count()) {
                            this.position = 0;
                        }
                        var index2 = this.position;
                        this.updateSlide(index1, index2, 'right');
                    },
                    switchLeft : function() {
                        var index1 = this.position;
                        this.position--;
                        if (this.position < 0) {
                            this.position = (this.count() - 1);
                        }
                        var index2 = this.position;
                        this.updateSlide(index1, index2, 'left');
                    },
                    updateSlide : function(index1, index2, direction) {
                        var slides = this.slides();
                        var slide1 = $(slides[index1]);
                        var slide2 = $(slides[index2]);
                        if (direction === 'left') {
                            this.fadeOut(slide1, direction);
                            this.fadeIn(slide2, direction);
                        } else {
                            this.fadeOut(slide1, direction);
                            this.fadeIn(slide2, direction);
                        }
                    },
                    fadeOut : function(slide, direction) {
                        slide.removeClass('fade-in');
                        if (direction === 'left') {
                            slide.addClass('fade-out-left');
                            setTimeout(function(){
                                slide.removeClass('fade-out-left');
                                slide.addClass('invisible');
                            }, 300);
                        } else {
                            slide.addClass('fade-out-right');
                            setTimeout(function(){
                                slide.removeClass('fade-out-right');
                                slide.addClass('invisible');
                            }, 300);
                        }
                    },
                    fadeIn : function(slide, direction) {
                        slide.removeClass('invisible');
                        if (direction === 'left') {
                            slide.addClass('position-right');
                            setTimeout(function(){
                                slide.addClass('fade-in');
                                slide.removeClass('position-right');
                            }, 100);
                        } else {
                            slide.addClass('position-left');
                            setTimeout(function(){
                                slide.addClass('fade-in');
                                slide.removeClass('position-left');
                            }, 100);
                        }
                    }
                };
                Carousel.right_arrow.on('click', function(){
                    Carousel.switchLeft();
                    Carousel.clicked = true;
                });
                Carousel.left_arrow.on('click', function(){
                    Carousel.switchRight();
                    Carousel.clicked = true;
                });

                var startPoll = function() {
                    setTimeout(carouselPoll, 5000);
                };
                var carouselPoll = function() {
                    if (!Carousel.clicked) {
                        Carousel.switchLeft();
                        setTimeout(carouselPoll, 5000);
                    }
                };
                startPoll();
            }
        },

        feature : {
            init : function() {
                var _this = this;
                $('.features-menu li').on('click', function(){
                    var menu = $(this).parent();
                    var menu_items = $(this).siblings();
                    var highlight = menu.siblings('.menu-bar').children('.highlight');
                    var cards = menu.siblings('.features-content').children('.card');
                    var item = _this.whichItem($(this));
                    menu_items.removeClass('active');
                    $(this).addClass('active');
                    if (item === 'one') {
                        var card = menu.siblings('.features-content').children('.one');
                        cards.addClass('invisible');
                        cards.removeClass('visible');
                        card.removeClass('invisible');
                        card.addClass('visible');
                        highlight.removeClass('middle');
                        highlight.removeClass('right');
                    } else if (item === 'two') {
                        var card = menu.siblings('.features-content').children('.two');
                        cards.addClass('invisible');
                        cards.removeClass('visible');
                        card.removeClass('invisible');
                        card.addClass('visible');
                        highlight.removeClass('right');
                        highlight.addClass('middle');
                    } else if (item === 'three') {
                        var card = menu.siblings('.features-content').children('.three');
                        cards.addClass('invisible');
                        cards.removeClass('visible');
                        card.removeClass('invisible');
                        card.addClass('visible');
                        highlight.removeClass('middle');
                        highlight.addClass('right');
                    }
                });
            },

            whichItem : function(item) {
                if (item.hasClass('one')) {
                    return 'one';
                } else if (item.hasClass('two')) {
                    return 'two';
                } else if (item.hasClass('three')) {
                    return 'three';
                }
            }
        },

        init : function() {
            var _this = this;
            $('.carousel').each(function() {
                _this.carousel.init($(this));
            });
            this.feature.init();
        }

    };

    $(document).ready(function(){

        Landing.init();

    });

})();
