/**
 * SamCart Core
 *
 * Copyright (C) 2014-2016 SamCart, LLC. All Rights Reserved.
 * This software may not be redistributed in any form.
 *
 */

(function(){

    "use strict";

    var Upgrade = {

        form : $('#upgrade_form'),
        email : $('#upgrade_form .email'),
        password : $('#upgrade_form .password'),
        submit : $('#upgrade_form .submit .blue.button'),
        inputs : $('#upgrade_form .email, #upgrade_form .password'),
        products : $('#upgrade_form .products'),
        error : $('#upgrade_form error h4'),
        conversionPixel : $("#conversionPixel"),

        init : function() {


            var _this = this;

            this.form.submit(function(e) {
                e.preventDefault();
                _this.form.removeClass('shake');
                if (_this.validate()) {
                    _this.submit.addClass('loading');
                    _this.submit.val("");
                    _this.upgrade({
                        'email' : _this.email.val(),
                        'password' : _this.password.val(),
                        'products' : _this.products.val()
                    });
                } else {
                    _this.shake();
                }
            });

            this.email.keydown(function(){
                var email = $(this).val();
                if (_this.validateEmail(email)) {
                    $(this).removeClass('invalid');
                    $(this).addClass('valid');
                } else {
                    $(this).removeClass('valid');
                }
            });

            this.email.blur(function(){
                var email = $(this).val();
                if (!_this.validateEmail(email) && email !== '') {
                    $(this).removeClass('valid');
                    $(this).addClass('invalid');
                } else {
                    $(this).removeClass('invalid');
                }
            });

            this.inputs.keydown(function() {
                var value = $(this).val();
                if (value !== '') {
                    $(this).removeClass('empty');
                }
            });

        },

        validate : function() {
            var _this = this;
            this.clear();
            this.inputs.each(function() {
                if ($(this).val() === '') {
                    $(this).addClass('empty');
                    return;
                }
                if ($(this).hasClass('email') && !_this.validateEmail(_this.email.val())) {
                    $(this).addClass('empty invalid');
                } else {
                    $(this).addClass('valid');
                }
            });

            var valid = true;

            this.inputs.each(function() {
                if ($(this).hasClass('empty') || $(this).hasClass('invalid')) {
                    valid = false;
                }
            });

            return valid;
        },

        validateEmail : function(email) {
            return /^.+@.+\..+$/.test(email);
        },

        clear : function() {
            this.inputs.removeClass('invalid');
            this.inputs.removeClass('empty');
            this.inputs.removeClass('valid');
            this.error.html('');
        },

        shake : function() {
            var _this = this;
            if (!_this.form.hasClass('shake')) {
                _this.form.addClass('shake');
            }
            setTimeout(function() {
                if (_this.form.hasClass('shake')) {
                    _this.form.removeClass('shake');
                }
            }, 821);
        },

        upgrade : function(form_data) {
            var _this = this;
            $.post('/upgrade', form_data, function(response) {
                if (response.success) {
                    if (response.data.upgrade_token !== '') {
                        $("#hidden_update_cc_form .token").val(response.data.upgrade_token);
                        $("#hidden_update_cc_form").submit();
                    } else {
                        _this.checkoutAnalytics(_this.products.val());
                        window.location = response.data.url_redirect;
                    }
                } else {
                    _this.submit.removeClass('loading');
                    _this.submit.val("Upgrade Now");
                    _this.unsuccessful(response.data);
                    _this.shake();
                }
            })
            .fail(function() {
                _this.submit.removeClass('loading');
                _this.submit.val("Upgrade Now");
                _this.error.html('There was an error communicating with SamCart');
            });
        },

        unsuccessful : function(data) {
            $('.login-error h4').html(data.message);
        },

        checkoutAnalytics : function(product) {
            if (product === 'samcart-go-package') {
                this.conversionPixel.attr('src', '//app.webinarjam.net/tracker?action=sale&webicode=1c16e1f447&productID=116094');
                fbq('track', 'Purchase', {
                    value: '997.00',
                    currency:'USD',
                    content_name: product,
                    purchase_type: 'new signup',
                    referrer: document.referrer
                });
            } else if (product === 'samcart-go-package-payment-plan') {
                this.conversionPixel.attr('src', '//app.webinarjam.net/tracker?action=sale&webicode=3a2028d746&productID=74159');
                fbq('track', 'Purchase', {
                    value: '99.00',
                    currency:'USD',
                    content_name: product,
                    purchase_type: 'new signup',
                    referrer: document.referrer
                });
            } else {
                fbq('track', 'Purchase', {
                    value: '0.00',
                    currency:'USD',
                    content_name: product,
                    purchase_type: 'new signup',
                    referrer: document.referrer
                });
            }
        }
    };

    $(document).ready(function(){
        Upgrade.init();
    });
})();
