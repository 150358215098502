/**
 * SamCart Core
 *
 * Copyright (C) 2014-2016 SamCart, LLC. All Rights Reserved.
 * This software may not be redistributed in any form.
 *
 */

(function(){

    "use strict";

    var Common = {

        mobile_menu_active : false,

        content : $('.toggle-wrapper'),
        mobile_menu : $('.hamburger-menu'),
        hamburger_icon : $('.hamburger svg use'),
        header : $('.marketing header'),

        init : function() {
            var _this = this;
            $('.hamburger').on('click', function() {
                _this.toggle_mobile_menu();
            });

            var header = $('header'),
                headerHeight = header.height(),
                treshold = 0,
                lastScroll = 0;

            $(document).on('scroll', function (evt) {
                var newScroll = $(document).scrollTop(),
                    diff = newScroll-lastScroll;

                if (newScroll > 0) {
                    header.addClass('drop-shadow-bottom');
                } else {
                    header.removeClass('drop-shadow-bottom');
                }

                // normalize treshold range
                treshold = (treshold+diff > headerHeight) ? headerHeight : treshold + diff;
                treshold = (treshold < 0) ? 0 : treshold;

                header.css('top', (-treshold)+'px');

                lastScroll = newScroll;
            });
        },

        toggle_mobile_menu : function() {

            if (this.mobile_menu_active) {
                this.content.show();
                this.mobile_menu.hide();
                this.hamburger_icon.attr('xlink:href', '#menu-icon');
                this.mobile_menu_active = !this.mobile_menu_active;
            } else {
                this.content.hide();
                this.mobile_menu.show();
                this.hamburger_icon.attr('xlink:href', '#thin-ex-icon');
                this.mobile_menu_active = !this.mobile_menu_active;
            }

        }

    };

    $(document).ready(function(){

        Common.init();

    });

})();
