/**
 * SamCart Core
 *
 * Copyright (C) 2014-2016 SamCart, LLC. All Rights Reserved.
 * This software may not be redistributed in any form.
 *
 */

(function(){

    "use strict";

    $(document).ready(function(){

        $('#price-switch').on('click', function(event){
            if (event.currentTarget.checked === true) {
                $(event.currentTarget).parent().children(".switch-no").css('color', '#c9d3dc');
                $(event.currentTarget).parent().children(".switch-yes").css('color', '#1d96f3');
                setTimeout(function(){
                    $('.price').html('$66');
                }, 150);
                $('.signup-link').attr('href', samcart.url + '/signup?plan=startup_yearly_30trial&source=pricing');
            } else {
                $(event.currentTarget).parent().children(".switch-no").css('color', '#1d96f3');
                $(event.currentTarget).parent().children(".switch-yes").css('color', '#c9d3dc');
                setTimeout(function(){
                    $('.price').html('$99');
                }, 150);
                $('.signup-link').attr('href', samcart.url + '/signup?source=pricing');
            }
        });

    });
})();
