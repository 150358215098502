/**
 * SamCart Core
 *
 * Copyright (C) 2014-2016 SamCart, LLC. All Rights Reserved.
 * This software may not be redistributed in any form.
 *
 */

(function(){

    "use strict";

    var Login = {

        form : $('#login_form'),
        email : $('#login_form #email'),
        password : $('#login_form #password'),
        redirect : $('#login_form .redirect'),
        eredirect : $('#login_form .eredirect'),
        submit : $("#login_form #submit"),
        inputs : $('#login_form #email, #login_form #password'),
        choose : $('section.login .choose'),
        error : $('#login_form error p'),

        init : function() {

            var _this = this;

            this.form.submit(function(e) {
                e.preventDefault();
                _this.form.removeClass('shake');
                if (_this.validate()) {
                    _this.login({
                        'email' : $('#email').val(),
                        'password' : $('#password').val(),
                        'redirect' : _this.redirect.val(),
                        'eredirect' : _this.eredirect.val()
                    });
                } else {
                    _this.shake();
                }
            });

            this.email.keydown(function(){
                var email = $(this).val();
                if (_this.validateEmail(email)) {
                    $(this).removeClass('invalid');
                    $(this).addClass('valid');
                } else {
                    $(this).removeClass('valid');
                }
            });

            this.email.blur(function(){
                var email = $(this).val();
                if (!_this.validateEmail(email) && email !== '') {
                    $(this).removeClass('valid');
                    $(this).addClass('invalid');
                } else {
                    $(this).removeClass('invalid');
                }
            });

            this.inputs.keydown(function() {
                var value = $(this).val();
                if (value !== '') {
                    $(this).removeClass('empty');
                }
            });

        },

        validate : function() {
            var _this = this;
            this.clear();
            this.inputs.each(function() {
                if ($(this).val() === '') {
                    $(this).addClass('empty');
                    return;
                }
                if ($(this).is('email') && !_this.validateEmail(_this.email.val())) {
                    $(this).addClass('empty invalid');
                } else {
                    $(this).addClass('valid');
                }
            });

            var valid = true;

            this.inputs.each(function() {
                if ($(this).hasClass('empty') || $(this).hasClass('invalid')) {
                    valid = false;
                }
            });

            return valid;
        },

        validateEmail : function(email) {
            return /^.+@.+\..+$/.test(email);
        },

        clear : function() {
            this.inputs.removeClass('invalid');
            this.inputs.removeClass('empty');
            this.inputs.removeClass('valid');
            this.error.html('');
        },

        shake : function() {
            var _this = this;
            if (!_this.form.hasClass('shake')) {
                _this.form.addClass('shake');
            }
            setTimeout(function() {
                if (_this.form.hasClass('shake')) {
                    _this.form.removeClass('shake');
                }
            }, 821);
        },

        login : function(form_data) {
            var _this = this;
            _this.submit.addClass('loading');
            _this.submit.val("");
            $.post('/login', form_data, function(data) {
                if (data.result === 'success') {
                    _this.success(data);
                } else {
                    _this.submit.removeClass('loading');
                    _this.submit.val("Login");
                    _this.unsuccessful(data);
                    _this.shake();
                }
            })
            .fail(function() {
                _this.submit.removeClass('loading');
                _this.submit.val("Login");
                this.error.html('There was an error communicating with SamCart');
            });
        },

        success : function(data) {
            if (data.action === 'redirect') {
                window.location = data.url;
            } else if (data.action === 'choose') {
                this.chooseHost(data.hosts);
            }
        },

        unsuccessful : function(data) {
            $('.login-error h4').html(data.message);
        },

        chooseHost : function(hosts) {
            this.form.addClass('fade-out');
            this.choose.addClass('fade-in');
            $('.login-show').hide();
            $('.choose-show').show();
            $.each(hosts, function() {
                var template = "<a href='" + this.url + "'><li>" + this.display_url + "</li></a>";
                $('.choose ul').append(template);
            });
        }
    };

    $(document).ready(function(){
        Login.init();
    });
})();
