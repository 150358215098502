/**
 * SamCart Core
 *
 * Copyright (C) 2014-2016 SamCart, LLC. All Rights Reserved.
 * This software may not be redistributed in any form.
 *
 */

(function(){

    "use strict";

    var UpgradeUpdateCC = {

        form : $('#update_cc_form'),
        cc_num : $('#update_cc_form input.cc-num'),
        cc_exp : $('#update_cc_form input.cc-exp'),
        cc_cvc : $('#update_cc_form input.cc-cvc'),
        submit : $('#update_cc_form .submit .blue.button'),
        inputs : $('#update_cc_form .input.cc-num, #update_cc_form .input.cc-exp, #update_cc_form .input.cc-cvc'),
        token : $("#update_cc_form .token"),
        products : $("#update_cc_form .products"),
        error : $('#update_cc_form .error h4'),
        form_data : {},
        conversionPixel : $("#conversionPixel"),

        init : function() {


            var _this = this;

            this.cc_num.payment('formatCardNumber');
            this.cc_exp.payment('formatCardExpiry');
            this.cc_cvc.payment('formatCardCVC');

            this.form.submit(function(e) {
                e.preventDefault();
                _this.form.removeClass('shake');
                if (_this.validate()) {
                    _this.submit.addClass('loading');
                    _this.submit.val("");
                    _this.form_data = {
                        'token' : _this.token.val(),
                        'products' : _this.products.val(),
                    };
                    _this.upgrade({
                        'cc_num' : _this.cc_num.val(),
                        'cc_exp' : _this.cc_exp.val(),
                        'cc_cvc' : _this.cc_cvc.val()
                    });
                } else {
                    _this.shake();
                }
            });

            this.cc_num.keyup(function(){
                _this.type = $.payment.cardType($(this).val());
                if (_this.type !== null) {
                    _this.greyCC();
                    _this.highlight(_this.type);
                } else {
                    _this.clearCC();
                }
            });

            this.cc_exp.keydown(function(e){
                var code = (e.keyCode ? e.keyCode : e.which);
                if ($(this).val().length === 7 && (code !== 8 && code !== 9 && code !== 46 && code !== 37 && code !== 38 && code !== 39 && code !== 40)) {
                    e.preventDefault();
                }
            });

            this.cc_num.on('blur', function() {
                _this.validateCC();
            });

            this.cc_exp.on('blur', function() {
                _this.validateExp();
            });

            this.cc_cvc.on('blur', function() {
                _this.validateCVC();
            });

            this.inputs.keydown(function() {
                var value = $(this).val();
                if (value !== '') {
                    $(this).removeClass('empty');
                }
            });

        },

        validate : function() {

            var _this = this;
            this.error.html('');
            this.checkEmpty();
            if (this.email_taken) {
                this.error.html('That email is already taken.');
                return false;
            }

            var empty = false;

            this.inputs.each(function() {
                if ($(this).hasClass('empty')) {
                    empty = true;
                }
            });

            if (empty) {
                this.error.html('All fields are required.');
                return false;
            }

            if (!this.validateCC()) {
                this.error.html('The card number is invalid.');
                return false;
            }

            if (!this.validateExp()) {
                this.error.html('The expiration date is invalid.');
                return false;
            }

            if (!this.validateCVC()) {
                this.error.html('The CVC you entered is invalid.');
                return false;
            }

            return true;
        },

        validateCC : function() {
            var cc_num = this.cc_num.val();
            if (cc_num === '') {
                this.cc_num.removeClass('valid');
                this.cc_num.addClass('invalid');
                return false;
            }
            this.cc_num.removeClass('empty');
            var valid = $.payment.validateCardNumber(cc_num);
            if (!valid) {
                this.cc_num.addClass('invalid');
                this.cc_num.removeClass('valid');
                return false;
            }
            this.cc_num.removeClass('invalid');
            this.cc_num.addClass('valid');
            return true;
        },

        validateExp : function() {
            var exp = this.cc_exp.val();
            if (exp === '') {
                this.cc_exp.removeClass('valid');
                this.cc_exp.addClass('invalid');
                return false;
            }
            this.cc_exp.removeClass('empty');
            exp = this.parseExp(exp);
            var valid = $.payment.validateCardExpiry(exp.month, exp.year);
            if (!valid) {
                this.cc_exp.addClass('invalid');
                this.cc_exp.removeClass('valid');
                return false;
            }
            this.cc_exp.removeClass('invalid');
            this.cc_exp.addClass('valid');
            return true;
        },

        validateCVC : function() {
            var cvc = this.cc_cvc.val();
            if (cvc === '') {
                this.cc_cvc.removeClass('valid');
                this.cc_cvc.addClass('invalid');
                return false;
            }
            this.cc_cvc.removeClass('empty');
            var card_type = $.payment.cardType(this.cc_num.val());
            var valid = false;
            if (card_type !== null) {
                valid = $.payment.validateCardCVC(cvc, card_type);
            } else {
                valid = $.payment.validateCardCVC(cvc);
            }
            if (!valid) {
                this.cc_cvc.addClass('invalid');
                this.cc_cvc.removeClass('valid');
                return false;
            }
            this.cc_cvc.removeClass('invalid');
            this.cc_cvc.addClass('valid');
            return true;
        },

        clearCC : function() {
            $('.cards .mastercard use').attr('xlink:href', '#mastercard');
            $('.cards .visa use').attr('xlink:href', '#visa');
            $('.cards .discover use').attr('xlink:href', '#discover');
            $('.cards .amex use').attr('xlink:href', '#amex');
            $('.cards .dinersclub use').attr('xlink:href', '#dinersclub');
            $('.cards .jcb use').attr('xlink:href', '#jcb');
        },

        greyCC : function() {
            $('.cards .mastercard use').attr('xlink:href', '#mastercard-gs');
            $('.cards .visa use').attr('xlink:href', '#visa-gs');
            $('.cards .discover use').attr('xlink:href', '#discover-gs');
            $('.cards .amex use').attr('xlink:href', '#amex-gs');
            $('.cards .dinersclub use').attr('xlink:href', '#dinersclub-gs');
            $('.cards .jcb use').attr('xlink:href', '#jcb-gs');
        },

        highlight : function(card) {
            $('.cards .' + card + ' use').attr('xlink:href', '#' + card);
        },

        clear : function() {
            this.inputs.removeClass('invalid');
            this.inputs.removeClass('empty');
            this.inputs.removeClass('valid');
            this.error.html('');
        },

        shake : function() {
            var _this = this;
            if (!_this.form.hasClass('shake')) {
                _this.form.addClass('shake');
            }
            setTimeout(function() {
                if (_this.form.hasClass('shake')) {
                    _this.form.removeClass('shake');
                }
            }, 821);
        },

        upgrade : function(form_data) {
            var exp_date = this.parseExp(form_data.cc_exp);
            Stripe.card.createToken({
                number: form_data.cc_num,
                cvc: form_data.cc_cvc,
                exp_month: exp_date.month,
                exp_year: exp_date.year
            }, this.stripeCallback);
        },

        stripeCallback : function(status, response) {
            if (status !== 200) {
                UpgradeUpdateCC.submit.removeClass('loading');
                UpgradeUpdateCC.submit.val("Upgrade My Account Now!");
                return;
            }
            if (response.error) {
                UpgradeUpdateCC.submit.removeClass('loading');
                UpgradeUpdateCC.submit.val("Upgrade My Account Now!");
                UpgradeUpdateCC.handleStripeError(response);
                return;
            }
            UpgradeUpdateCC.postUpgradeUpdateCC(UpgradeUpdateCC.form_data, response);
        },

        unsuccessful : function(data) {
            $('.login-error h4').html(data.message);
        },

        checkoutAnalytics : function(slug, upgrade_id) {
            this.conversionPixel.attr('src', 'https://app.webinarjam.net/tracker?action=sale&webicode=e335991119&productID=35442');
            fbq('track', 'Purchase', {
                value: '997.00',
                currency:'USD',
                content_name: slug,
                order_id: upgrade_id,
                purchase_type: 'upgrade',
                referrer: document.referrer
            });
        },

        checkEmpty : function() {
            this.inputs.removeClass('empty');
            this.inputs.each(function() {
                if ($(this).val() === '') {
                    $(this).addClass('empty');
                    return;
                }
            });
        },

        postUpgradeUpdateCC : function(form_data, response) {
            var _this = this;
            form_data.stripe_token = response.id;
            $.post('/upgrade_with_cc', form_data, function(post_response) {
                if (post_response.success) {
                    _this.checkoutAnalytics(post_response.data.plan, post_response.data.upgrade_id);
                    window.location = post_response.data.url_redirect;
                } else {
                    _this.handleUpgradeError(post_response.data);
                }
            });
        },

        handleUpgradeError : function(response) {
            this.error.html(response.message);
            this.submit.removeClass('loading');
            this.submit.val("Upgrade My Account Now!");
            this.shake();
        },

        parseExp : function(exp) {
            var date = exp.split(' / ');
            return {
                month : date[0],
                year : date[1]
            };
        }
    };

    $(document).ready(function(){
        UpgradeUpdateCC.init();
    });
})();
