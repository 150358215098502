$(document).ready(function () {

    var selectThisButton = function (button) {
        $('.plan-option').removeClass('select')
        $('.plan-option').addClass('no-select')
        button.removeClass('no-select')
        button.addClass('select')
    };

    var showPremium = function () {
        $('.modal-section .selected').show();
        $('.modal-section .not-selected').hide();
    };

    var hidePremium = function () {
        $('.modal-section .selected').hide();
        $('.modal-section .not-selected').show();
    };

    $('.modal-show').on('click',function () {
        $('.modal-section').show();
        $('body').css('overflow', 'hidden');
    });
    $('.modal-close').on('click',function () {
        $('.modal-section').hide();
        $('body').css('overflow', 'scroll');
    });

    $('.premium-button').on('click',function () {
        showPremium();
        selectThisButton($(this));
    });

    $('.pro-button').on('click',function () {
        hidePremium();
        selectThisButton($(this));
    });

    $('#signup-pro-button').on('click',function () {
        hidePremium();
        selectThisButton($('#signup-pro-toggle'));
    });


    $('#signup-premium-button').on('click',function () {
        showPremium();
        selectThisButton($('#signup-premium-toggle'));
    });

    $('#signup-pro-button').on('click',function () {
        hidePremium();
        selectThisButton($('#signup-pro-toggle'));
    });

});
